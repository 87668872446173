<!-- 登录，关注公众号页面 -->
<template>
  <div class="body">
    <div class="top">
      <div class="top_box">
        <span class="span_btn" @click="nextFunc">下一步</span>
        <span class="span_btn" @click="nextFunc">关闭</span>
      </div>
    </div>
    <div class="show_box">
      <div class="mid_info">
        <p class="tip_tit">{{ title_txt1 }}</p>
        <div class="info_item">
          <i class="el-icon-circle-check"></i>
          <span>{{ desc_txt1 }}</span>
        </div>
        <div class="info_item">
          <i class="el-icon-circle-check"></i>
          <span>{{ desc_txt2 }}</span>
        </div>
        <div class="info_item">
          <i class="el-icon-circle-check"></i>
          <span>{{ desc_txt3 }}</span>
        </div>
        <span class="add_btn" @click="attentionGzh">点击关注公众号</span>
      </div>
      <!-- <div class="code_box">
        <img class="code_img" src="https://newoss.zhulong.com/forum/202408/14/30/153930zdfrlwt8udxds9xc.jpg" alt="">
        <div class="tips">长按识别二维码<br>关注公众号</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import WechatState from '@/utils/isWechatState.js'
import wx from 'weixin-js-sdk'
import { getShares } from '@/api/hometop.js'
import Cookies from 'js-cookie'

export default {
  data() {
    return {
      redirect: this.$route.query.redirect || window.location.origin,
      openGzhUrl: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzA3MjIyMjMzNA==#wechat_redirect',
      isWechatH5: WechatState.isWechat,
      isWechatMini: WechatState.isWechatMiniprogram,
      isZkb: false,
      title_txt1: '关注筑龙学社公众号',
      desc_txt1: '行业大咖精彩直播',
      desc_txt2: '行业热点政策解读',
      desc_txt3: '免费精品课程资料'
    }
  },
  created() {
    const isZk = location.host.indexOf('zk')
    const isZk468 = location.host.indexOf('zk468')
    if (Cookies.get('agency_id') == 11 || isZk !== -1 || isZk468 !== -1) {
      this.isZkb = true
      this.title_txt1 = '关注筑龙学社公众号才能'
      this.desc_txt1 = '定制学习任务'
      this.desc_txt2 = '接收考试信息'
      this.desc_txt3 = '免费领取注考币'
    } else {
      this.isZkb = false
    }
  },
  methods: {
    wxShareConfig() {
      var info = {
        url: window.location.href.split('#')[0]
      }
      getShares(info).then(res => {
        console.log(res)
        const result = res.result ? res.result : {}
        const wxconf = result.wxconf ? result.wxconf : {}
        this.checkOpenMiniprogram(wxconf)
      })
    },
    checkOpenMiniprogram(data) {
      // 微信 h5 打开小程序
      this.openMiniConfigNext(data)
    },
    openMiniConfigNext(data) {
      this.$toast(JSON.stringify(data))
      // const _this = this
      wx.config({
        appId: data.appId,
        // 必填，生成签名的时间戳
        timestamp: data.timestamp,
        // 必填，生成签名的随机串
        nonceStr: data.nonceStr,
        // 必填，签名
        signature: data.signature,
        // 必填，需要使用的JS接口列表，且任意填写
        jsApiList: ['scanQRCode'],
        // 可选，需要使用的开放标签列表，wx-open-launch-weapp 指H5跳转小程序 wx-open-launch-app 指H5跳转app
        openTagList: ['wx-open-launch-weapp']
      })
      wx.ready(function() {
        // _this.$toast('ready')
        var btn = document.getElementById('launch-btn')
        // launch 用户点击跳转按钮并对确认弹窗进行操作后触发
        btn.addEventListener('launch', function(e) {
          console.log(e, 'success')
        })
        // error 用户点击跳转按钮后出现错误
        btn.addEventListener('error', function(e) {
          console.log(e.detail, 'fail')
          // _this.$toast('click 错误 = ' + e.detail)
        })
      })
      wx.error(function(res) {
        console.log(res, 'error')
        // _this.$toast('错误 = ' + res)
      })
    },
    // 关注公众号
    attentionGzh() {
      window.open(this.openGzhUrl, '_blank')
    },
    // 下一步、关闭
    nextFunc() {
      window.location.href = this.redirect
    }
  }
}
</script>

<style lang="scss" scoped>
.top {
  width: 100%;
  height: 90px;
  position: relative;
  .top_box {
    position: fixed;
    padding: 0 30px;
    width: calc(100% - 60px);
    height: 90px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .span_btn {
      display: inline-block;
      height: 90px;
      line-height: 90px;
      width: 100px;
      text-align: left;
      font-size: 28px;
      color: #333;
      font-weight: 600;
    }
    .span_btn:last-child {
      text-align: right;
    }
  }
}
.show_box {
  width: 100%;
  // height: calc(100vh - 90px);
  // display: flex;
  // flex-direction: column;
  // justify-content: space-around;
}
.tip_tit {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 10%;
}
.mid_info {
  margin-top: 10%;
  padding: 30px 0;
  text-align: center;
  .info_item {
    text-align: center;
    margin: 10px 0;
    height: 40px;
    line-height: 40px;
    font-size: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 28px;
      margin-right: 10px;
      color: #ee2e2e;
    }
  }
  .add_btn {
    margin-top: 30px;
    display: inline-block;
    width: 350px;
    height: 65px;
    line-height: 65px;
    text-align: center;
    color: #fff;
    font-size: 28px;
    font-weight: 600;
    border-radius: 35px;
    background-color: #ee2e2e;
  }
}
.ifrmas {
  width: 100%;
  height: 400px;
}
.code_box {
  padding: 30px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  .code_img {
    width: 260px;
    height: 260px;
    display: block;
  }
}
.tips {
  margin-top: 30px;
  text-align: center;
  font-size: 28px;
  color: #333;
  font-weight: 600;
}
</style>
